import React from 'react'
import Markdown from 'react-markdown'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(({ markdown }) => ({ markdown }))

export default function EventInfoListItem({ label, value, markdown }) {
  const { spacing, palette } = useTheme()
  const classes = useStyles()

  return (
    <div style={{ padding: spacing(3, 0), borderBottom: `1px solid ${palette.divider}` }}>
      <Typography variant="caption">{label}</Typography>
      {markdown ? (
        <Markdown source={value} className={classes.markdown} />
      ) : (
        <Typography variant="body2">{value}</Typography>
      )}
    </div>
  )
}
