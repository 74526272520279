import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useTheme } from '@material-ui/core/styles'

import CustomButton from 'components/CustomButton'
import { useEvent } from 'hooks/useEvent'
import placeholder from 'images/placeholder.png'

export default function Certificate() {
  const { push } = useHistory()
  const { id } = useParams()
  const { palette, spacing } = useTheme()
  const { getEvent } = useEvent()
  const [eventResource, setEventResource] = useState(null)
  const { event, activity, privileges } = eventResource || {}

  useEffect(() => {
    getEvent(id).then(setEventResource)
  }, [getEvent, id])

  if (!eventResource) {
    return null
  }

  if (!activity.uploaded) {
    push('/')
  }

  return (
    <div style={{ background: palette.background.paper, padding: spacing(6), textAlign: 'center' }}>
      <img
        style={{ maxWidth: 320, width: '100%', height: 'auto' }}
        alt="完走証"
        src={activity.finisher_certificate_image_url}
        onError={event => (event.target.src = placeholder)}
      />
      <form method="GET" action={activity.finisher_certificate_image_url}>
        <CustomButton type="submit" style={{ marginTop: spacing(6) }} arrow>
          完走証のダウンロード
        </CustomButton>
      </form>
      {privileges && privileges.length > 0 && (
        <CustomButton
          style={{ marginTop: spacing(3) }}
          onClick={() => push(`/events/${event.id}/privileges/me`)}
          arrow
        >
          取得した完走特典({privileges.length})
        </CustomButton>
      )}
    </div>
  )
}
