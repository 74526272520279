import React from 'react'

import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import SectionHeader from 'components/SectionHeader'

export default function Concept() {
  const { spacing, palette } = useTheme()

  return (
    <div style={{ background: palette.background.paper }}>
      <SectionHeader primary="CONCEPT" />
      <div
        style={{
          margin: spacing(0, 3),
          padding: spacing(3),
        }}
      >
        <Typography variant="body2">{process.env.REACT_APP_CONCEPT_LEAD}</Typography>
      </div>
    </div>
  )
}
