import React, { useMemo } from 'react'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import SectionHeader from 'components/SectionHeader'
import fitbit from 'images/tracker/logo/fitbit.png'
import garmin from 'images/tracker/logo/garmin.png'
import polar from 'images/tracker/logo/polar.png'
import suunto from 'images/tracker/logo/suunto.png'

export default function TrackerLogoList() {
  const { palette, spacing } = useTheme()
  const xs = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'))
  const rect = { w: 90, h: 24 } // 画像サイズ

  const wrapperWidth = useMemo(() => {
    if (xs) {
      const space = spacing(3 * 2) // 左右の余白合計
      const row = 2 // 列数
      return (rect.w + space) * row
    } else {
      return '100%'
    }
  }, [rect, spacing, xs])

  return (
    <div style={{ background: palette.background.paper }}>
      <SectionHeader primary="SUPPORT" />
      <div
        style={{
          padding: spacing(3),
          width: wrapperWidth,
          flex: 1,
          display: 'flex',
          flexWrap: 'wrap',
          margin: xs ? '0 auto' : 0,
        }}
      >
        {[garmin, polar, fitbit, suunto].map((src, i) => (
          <div style={{ padding: spacing(3) }} key={i}>
            <img style={{ width: rect.w, height: rect.h }} src={src} alt="tracker" />
          </div>
        ))}
      </div>
    </div>
  )
}
