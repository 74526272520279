import React, { useEffect, useState } from 'react'

import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import placeholder from 'images/placeholder.png'

export default function MainImage({ src, alt, type, overlayText }) {
  const [width, setWidth] = useState(Math.min(window.innerWidth, 800))
  const xs = useMediaQuery(({ breakpoints }) => breakpoints.down('xs'))
  const config = {
    top: { mobile: 17 / 16, pc: 1 / 2 },
    event: { mobile: 2 / 3, pc: 21 / 40 },
    privilege: { mobile: 1 / 2, pc: 1 / 2 },
  }
  const aspectRatio = config[type][xs ? 'mobile' : 'pc']

  useEffect(() => {
    const updateWidth = () => {
      setWidth(Math.min(window.innerWidth, 800))
    }
    window.addEventListener('resize', updateWidth)
    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  return (
    <div style={{ position: 'relative', height: width * aspectRatio }}>
      <img
        style={{ objectFit: 'cover', width: '100%', height: width * aspectRatio }}
        src={src}
        alt={alt}
        onError={event => (event.target.src = placeholder)}
      />
      {overlayText && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'rgba(0,0,0,0.4)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="body1" style={{ fontWeight: 'bold', color: '#fff' }}>
            {overlayText}
          </Typography>
        </div>
      )}
    </div>
  )
}
