import React from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import CustomButton from 'components/CustomButton'
import { ReactComponent as CloseIcon } from 'images/icons/close.svg'

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiDialog-paper': {
      margin: theme.spacing(4),
    },
  },
}))

export default function Popup({
  id = 'popup',
  open = true,
  title,
  description,
  buttonDirection = 'column',
  primaryText,
  onClickPrimary,
  secondaryText,
  onClickSecondary,
  onClose,
}) {
  const { spacing } = useTheme()
  const classes = useStyles()
  return (
    <Dialog id={id} className={classes.dialog} open={open} onClose={onClose}>
      <IconButton
        onClick={onClose}
        style={{ position: 'absolute', top: spacing(1), right: spacing(1) }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle style={{ textAlign: 'center', padding: spacing(4, 10) }} disableTypography>
        <Typography variant="h3">{title}</Typography>
      </DialogTitle>
      <DialogContent id="dialog-content">
        {typeof description === 'string' ? (
          <Typography variant="body2">{description}</Typography>
        ) : (
          description
        )}
      </DialogContent>
      {buttonDirection === 'row' ? (
        <DialogActions style={{ padding: spacing(4) }}>
          {secondaryText && (
            <Button style={{ flex: 1, minWidth: 120 }} color="secondary" onClick={onClickSecondary}>
              {secondaryText}
            </Button>
          )}
          <CustomButton style={{ flex: 1, minWidth: 120 }} onClick={onClickPrimary}>
            {primaryText}
          </CustomButton>
        </DialogActions>
      ) : (
        <DialogActions style={{ padding: spacing(4), flexDirection: 'column' }}>
          <CustomButton onClick={onClickPrimary}>{primaryText}</CustomButton>
          {secondaryText && (
            <Button
              style={{ marginTop: spacing(4), marginLeft: 0, padding: 0 }}
              color="secondary"
              onClick={onClickSecondary}
            >
              {secondaryText}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  )
}
