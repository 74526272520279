import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import moment from 'moment'

import CustomButton from 'components/CustomButton'
import { Competition } from 'hooks/useEvent'

const useStyles = makeStyles(theme => ({
  labelText: {
    width: 60,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2,
    padding: '1px 0',
  },
}))

export default function EventListItem({ eventResource, enableCertificate }) {
  const classes = useStyles()
  const { spacing, palette } = useTheme()
  const { push } = useHistory()
  const { event, activity, privileges } = eventResource
  const xs = useMediaQuery(({ breakpoints }) => breakpoints.only('xs'))

  const Title = () => (
    <div style={{ flex: 1 }}>
      <div style={{ display: 'flex', margin: spacing(3, 0) }}>
        <Typography
          variant="caption"
          className={classes.labelText}
          style={{
            width: 96,
            background:
              event.competition === Competition.RUN
                ? process.env.REACT_APP_COLOR_RUN_LABEL
                : process.env.REACT_APP_COLOR_WALK_LABEL,
            color: process.env.REACT_APP_COLOR_LABEL_TEXT,
            fontWeight: 'bold',
          }}
        >
          {event.competitionText}
        </Typography>
        {event.distance > 0 && (
          <Typography
            variant="caption"
            className={classes.labelText}
            style={{
              padding: 0,
              marginLeft: spacing(2),
              border: `1px solid ${palette.text.secondary}`,
              fontWeight: 'bold',
            }}
          >
            {`${parseInt(event.distance / 1000)}km`}
          </Typography>
        )}
      </div>
      <Link to={`/events/${event.id}`}>
        <Typography style={{ color: palette.link }} variant="body1">
          {event.title}
        </Typography>
      </Link>
    </div>
  )

  const Date = () => {
    if (moment(event.start_time).isSame(event.end_time, 'day')) {
      return <Typography variant="h2">{moment(event.start_time).format('MM/DD')}</Typography>
    } else {
      return (
        <>
          <Typography variant="h2" align="center" style={{ lineHeight: 1.0 }}>
            {moment(event.start_time).format('MM/DD')}
            <br />~<br />
            {moment(event.end_time).format('MM/DD')}
          </Typography>
        </>
      )
    }
  }

  const Actions = ({ style }) => {
    return (
      <div style={{ marginTop: spacing(3) }}>
        <CustomButton style={style} onClick={() => push(`/events/${event.id}/activities/me`)} arrow>
          完走証
        </CustomButton>
        {privileges.length > 0 && (
          <CustomButton
            style={{ marginTop: spacing(3), ...style }}
            onClick={() => push(`/events/${event.id}/privileges/me`)}
            arrow
          >
            取得した完走特典({privileges.length})
          </CustomButton>
        )}
      </div>
    )
  }

  return (
    <div
      style={{
        margin: spacing(0, 3),
        padding: spacing(2, 3, 5),
        borderBottom: `1px solid ${palette.divider}`,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div style={{ marginRight: spacing(6) }}>
          <Typography
            style={{ margin: spacing(3, 0, 2) }}
            variant="caption"
            component="p"
            color="textPrimary"
          >
            {moment(event.start_time).format('YYYY')}
          </Typography>
          <Date />
        </div>
        <Title />
        {!xs && enableCertificate && activity.uploaded && (
          <>
            <div style={{ flex: 1 }} />
            <Actions style={{ width: 220 }} />
          </>
        )}
      </div>
      {xs && enableCertificate && activity.uploaded && <Actions />}
    </div>
  )
}
