import React, { useEffect, useMemo, useState } from 'react'

import FormHelperText from '@material-ui/core/FormHelperText'
import { useTheme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

export default function BirthdayPicker({ onChange, error }) {
  const { spacing } = useTheme()
  const [year, setYear] = useState(null)
  const [month, setMonth] = useState(null)
  const [date, setDate] = useState(null)

  useEffect(() => {
    if (year && month && date) {
      const birthday = moment()
        .year(year)
        .month(month - 1)
        .date(date)
        .format('YYYY-MM-DD')
      onChange(birthday)
    }
  }, [year, month, date, onChange])

  const years = useMemo(() => {
    const from = moment().add(-10, 'years').year()
    const to = moment().add(-100, 'years').year()
    const _years = []
    for (let i = from; i >= to; i--) {
      _years.push(i)
    }
    return _years
  }, [])

  const months = useMemo(() => {
    return [...Array(12).keys()].map(i => i + 1)
  }, [])

  const dates = useMemo(() => {
    let endOfMonth = 31
    if (year && month) {
      endOfMonth = moment()
        .year(year)
        .month(month - 1)
        .endOf('month')
        .date()
    }
    return [...Array(endOfMonth).keys()].map(i => i + 1)
  }, [year, month])

  return (
    <div style={{ padding: spacing(2, 0) }}>
      <Typography variant="caption" color={error === undefined ? 'textSecondary' : 'error'}>
        生年月日
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          color="secondary"
          select
          SelectProps={{ native: true }}
          style={{ flex: 2, marginRight: spacing(2) }}
          onChange={e => setYear(e.target.value)}
          error={error !== undefined}
        >
          <option></option>
          {years.map(x => (
            <option key={x}>{x}</option>
          ))}
        </TextField>
        <Typography variant="body2">年</Typography>
        <TextField
          color="secondary"
          select
          SelectProps={{ native: true }}
          style={{ flex: 1, marginRight: spacing(2), marginLeft: spacing(4) }}
          onChange={e => setMonth(e.target.value)}
          error={error !== undefined}
        >
          <option></option>
          {months.map(x => (
            <option key={x}>{x}</option>
          ))}
        </TextField>
        <Typography variant="body2">月</Typography>
        <TextField
          color="secondary"
          select
          SelectProps={{ native: true }}
          style={{ flex: 1, marginRight: spacing(2), marginLeft: spacing(4) }}
          onChange={e => setDate(e.target.value)}
          error={error !== undefined}
        >
          <option></option>
          {dates.map(x => (
            <option key={x}>{x}</option>
          ))}
        </TextField>
        <Typography variant="body2">日</Typography>
      </div>
      {error && <FormHelperText error>必須項目です</FormHelperText>}
    </div>
  )
}
