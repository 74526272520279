import React, { useMemo, useState } from 'react'

import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

import CustomButton from 'components/CustomButton'
import MainImage from 'components/MainImage'
import MultilineText from 'components/MultilineText'
import Popup from 'components/Popup'

export default function PrivilegeListItem({ data, onSubmit }) {
  const { spacing, palette } = useTheme()
  const [openConfirm, setOpenConfirm] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const { privilege, is_used } = data

  const attention = useMemo(() => {
    const SHORT_TEXT_LENGTH = 42
    return expanded ? privilege.attention : privilege.attention.slice(0, SHORT_TEXT_LENGTH) + '...'
  }, [expanded, privilege.attention])

  const handleSubmit = () => {
    onSubmit()
    setOpenConfirm(false)
  }

  const Action = () => {
    if (is_used) {
      return (
        <Typography style={{ fontWeight: 'bold' }} color="primary">
          利用済み
        </Typography>
      )
    }

    if (moment() < moment(privilege.start_time) || moment() > moment(privilege.end_time)) {
      return (
        <>
          <CustomButton disabled>特典を利用</CustomButton>
          <Typography style={{ marginTop: spacing(2), fontSize: 12 }} color="primary">
            有効期間外のため利用できません。
          </Typography>
        </>
      )
    }

    return <CustomButton onClick={() => setOpenConfirm(true)}>特典を利用</CustomButton>
  }

  return (
    <>
      <div
        style={{
          background: palette.background.paper,
          paddingBottom: spacing(3),
          marginBottom: spacing(6),
        }}
      >
        <MainImage src={privilege.cover_image_url} type="privilege" alt="privilege.title" />
        <div style={{ textAlign: 'center', padding: spacing(3) }}>
          <Typography style={{ marginBottom: spacing(2), fontWeight: 'bold' }}>
            {privilege.title}
          </Typography>
          <Typography style={{ marginBottom: spacing(3), fontSize: 12, fontWeight: 'bold' }}>
            有効期限: {moment(privilege.start_time).format('YYYY年MM月DD日')}〜
            {moment(privilege.end_time).format('MM月DD日')}
          </Typography>
          <Action />
          <Button
            align="right"
            size="small"
            style={{ marginTop: spacing(1), fontSize: 12, fontWeight: 'bold', color: palette.link }}
            onClick={() => window.open(privilege.description_url)}
          >
            利用の詳細はこちら
          </Button>
        </div>
        <div style={{ padding: spacing(0, 3) }}>
          <Typography style={{ fontSize: 12, fontWeight: 'bold' }}>利用時の注意事項</Typography>
          <Typography style={{ fontSize: 12 }}>
            <MultilineText>{attention}</MultilineText>
          </Typography>
        </div>
        {!expanded && (
          <div style={{ padding: spacing(0, 3), textAlign: 'right' }}>
            <Button
              align="right"
              size="small"
              style={{ fontSize: 12, color: palette.link }}
              onClick={() => setExpanded(true)}
            >
              すべて読む
            </Button>
          </div>
        )}
      </div>
      {openConfirm && (
        <Popup
          onClose={() => setOpenConfirm(false)}
          title="特典利用"
          description={
            <Typography variant="body2">
              本当に利用しますか？
              <br />
              「利用する」を押すと、この特典はご利用いただけなくなります。ご注意ください。
            </Typography>
          }
          primaryText="利用する"
          onClickPrimary={handleSubmit}
          secondaryText="キャンセル"
          onClickSecondary={() => setOpenConfirm(false)}
        />
      )}
    </>
  )
}
